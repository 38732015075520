<template>
  <!-- Menu -->
  <ul class="nk-menu nk-menu-main">
    <router-link v-for="route in routes" :key="route.path" :to="route.path" custom
                 v-slot="{href, navigate, isActive, isExactActive}">
      <li :class="{'nk-menu-item': true, 'active': isExactActive || isActive}">
        <a :href="href" @click="navigate" class="nk-menu-link">
          <span class="nk-menu-text">{{ route.name }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import useAuth from '@/providers/auth';
export default {
  name: "Menu",
  setup() {
    const {isAdmin} = useAuth();
    const {isSoloVista} = useAuth();
    const routes = [
      {
        path: '/sistema/inicio',
        name: 'Inicio',
      },
      {
        path: '/sistema/empleados',
        name: 'Empleados'
      }
    ];

    if(isAdmin.value) {
      routes.push({
        path: '/sistema/servicios',
        name: 'Servicios'
      });
      routes.push({
        path: '/sistema/usuarios',
        name: 'Usuarios'
      });
      routes.push({
        path: '/sistema/configuracion',
        name: 'Configuracion'
      });
    }

    if(isSoloVista.value) {
      routes.push({
        path: '/sistema/servicios',
        name: 'Servicios'
      });
    }

    return {routes};
  }
}
</script>

<style scoped>

</style>